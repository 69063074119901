import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Collection } from '../../../shared/tables/collection';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { TableService } from 'src/app/shared/service/table.service';
import { SortEvent } from 'src/app/shared/directives/shorting.directive';
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ProductService } from 'src/app/shared/service/product.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
  providers: [TableService, DecimalPipe],
})


export class CollectionComponent implements OnInit {
  public closeResult: string;

  searchText;
  tableItem$: Observable<Collection[]>;
  total$: Observable<number>;

  collectionForm: FormGroup;
  submitted = false;
  
  status: "initial" | "uploading" | "success" | "fail" = "initial"; // Variable to store file status
  file: File | null = null; // Variable to store file

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(
    public service: TableService, 
    private modalService: NgbModal, 
    private formBuilder: FormBuilder, 
    private productService: ProductService,
    private router: Router
  ) {
    this.tableItem$ = service.tableItem$;
    this.total$ = service.total$;
  }

  onSort({ column, direction }) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  open(content) {
    this.collectionForm.reset()
    this.file = null
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.collectionForm = this.formBuilder.group(
      {
        image: [null],
        name: ['', Validators.required],
        status: [true, Validators.required],
        collection: ['', [Validators.required, Validators.pattern(/^[a-z0-9-]*$/)]],
      }
    );
    this.productService.collectionGet().subscribe((res) => {
      this.service.setUserData(res)
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.collectionForm.controls;
  }

  onChange(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      this.status = "initial";
      this.file = file;
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.collectionForm.invalid) {
      alert(
        'Invalid Form'
      );
      return
    }    
    
    const formData = new FormData();
    formData.append('name', this.collectionForm.value['name']);
    formData.append('collection', this.collectionForm.value['collection']);
    formData.append('image', this.file);
    formData.append('status', this.collectionForm.value['status']);

    console.log('Image: ', (formData.get('image')))
    console.log('Name: ', (formData.get('name')))
    console.log('Collection: ', (formData.get('collection')))
    console.log('Status: ', (formData.get('status')))

    const upload$ = this.productService.collectionCreate(formData);

    this.status = 'uploading';

    upload$.subscribe({
      next: (res) => {
        console.log("Response: ", res)
        this.status = 'success';
        this.router.navigateByUrl('/products', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/products/collection']);
        });
      },
      error: (error: any) => {
        console.log("Error: ", error.error.detail)
        this.status = 'fail';
        return;
      },
    });
  }

  onReset() {
    this.submitted = false;
    this.collectionForm.reset();
  }

  onDelete(id: number) {
    this.productService.collectionDelete(id).subscribe((res) => {
      console.log("Deleted Collection with ID: ", id)
    })
    this.router.navigateByUrl('/products', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/products/collection']);
    }); 
  }
}

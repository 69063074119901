<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Products collection</h5>
                </div>
                <div class="card-body">
                    <div class="btn-popup pull-right">
                        <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                            data-target="#exampleModal" (click)="open(content)">Add collection</button>
                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <h5 class="modal-title f-w-600" id="exampleModalLabel">Add Physical Product</h5>
                            </div>
                            <div class="modal-body">
                                <form class="needs-validation" [formGroup]="collectionForm">
                                    <div class="form">
                                        <div class="form-group">
                                            <label for="validationCustom01" class="mb-1">Collection Name:</label>
                                            <input class="form-control" type="text" formControlName="name" placeholder="Digital Printing">
                                            <div *ngIf="submitted && f['name'].errors && f['name'].errors['required']" class="form-error">Please fill out this field</div>
                                        </div>
                                        <div class="form-group">
                                            <label for="validationCustom01" class="mb-1">Status:</label>
                                            <select class="form-control" formControlName="status">
                                                <option value=true>Enable</option>
                                                <option value=false>Disable</option>
                                            </select>
                                            <div *ngIf="submitted && f['status'].errors && f['status'].errors['required']" class="form-error">Please fill out this field</div>
                                        </div>
                                        <div class="form-group">
                                            <label for="validationCustom01" class="mb-1">Collection label:</label>
                                            <input class="form-control" type="text" formControlName="collection" placeholder="digital-printing">
                                            <div *ngIf="submitted && f['collection'].errors && f['collection'].errors['required']" class="form-error">Please fill out this field</div>
                                            <div *ngIf="submitted && f['collection'].errors && f['collection'].errors['pattern']" class="form-error">Wrong pattern: only use - and no capital</div>
                                        </div>
                                        <div class="form-group mb-0">
                                            <label for="validationCustom02" class="mb-1">Collection Image :</label>
                                            <input class="form-control form-file" type="file" name="file" (change)="onChange($event)" #fileUpload >
                                            <div *ngIf="submitted && f['image'].errors && f['image'].errors['required']" class="form-error">Please fill out this field</div>
                                            <div *ngIf="file">
                                                <section class="file-info">
                                                  File details:
                                                  <ul>
                                                    <li>Name: {{file.name}}</li>
                                                    <li>Type: {{file.type}}</li>
                                                    <li>Size: {{file.size}} bytes</li>
                                                  </ul>
                                                </section>
                                              
                                                <section [ngSwitch]="status">
                                                  <p *ngSwitchCase="'uploading'">⏳ Uploading...</p>
                                                  <p *ngSwitchCase="'success'">✅ Done!</p>
                                                  <p *ngSwitchCase="'fail'">❌ Error!</p>
                                                  <p *ngSwitchDefault>😶 Waiting to upload...</p>
                                                </section>
                                              </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="onSubmit()">Save</button>
                                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                    (click)="modal.dismiss('Cross click')">Close</button>
                            </div>
                        </ng-template>
                    </div>
                    <div id="batchDelete" class="category-table custom-datatable">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col" sortable="img"  (sort)="onSort($event)">Image</th>
                                    <th scope="col" sortable="name" (sort)="onSort($event)">Name</th>
                                    <th scope="col" sortable="status" (sort)="onSort($event)">Status</th>
                                    <th scope="col" sortable="collection" (sort)="onSort($event)">Collection</th>
                                    <th scope="col" sortable="population" (sort)="onSort($event)">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor = 'let col of tableItem$ | async'>
                                <th scope="row">{{ col.id }}</th>
                                <td>
                                    <img [src]="col.image_url" (error)="$event.target.src = '../../../../assets/images/dashboard/product/1.jpg'" class="imgTable" style="width: 20px">
                                </td>
                                <td>{{ col.name }}</td>
                                <td>
                                    <i *ngIf="col.status" class='fa fa-circle font-success f-12'></i>
                                    <i *ngIf="!col.status" class='fa fa-circle font-warning f-12'></i>
                                </td>
                                <td>{{ col.collection }}</td>
                                <td>
                                    <a (click)="onDelete(col.id)"><i class="fa fa-trash-o"></i></a>
                                </td>
                                </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-center p-2">
                                <ngb-pagination
                                   [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                                   </ngb-pagination>
                             </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Discount Coupon Details</h5>
        </div>
        <div class="card-body tab2-card">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                  <a ngbNavLink>General</a>
                  <ng-template ngbNavContent>
                    <form [formGroup]="generalForm"  class="needs-validation" novalidate="">
                        <h4>General</h4>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <div class="col-xl-3 col-md-4">
                                        <label for="validationCustom0"><span>*</span>
                                            Coupan Title</label>
                                    </div>
                                    <div class="col-md-7">
                                        <input class="form-control" formControlName="name"
                                        id="validationCustom0" type="text" required="">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-xl-3 col-md-4">
                                        <label for="validationCustom1"><span>*</span>
                                            Coupan Code</label>
                                    </div>
                                    <div class="col-md-7">
                                        <input class="form-control" formControlName="code"
                                        id="validationCustom1" type="text" required="">
                                    </div>
                                    <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-xl-3 col-md-4">
                                        <label>Start Date</label>
                                    </div>
                                    <div class="col-md-7 ">
                                        <div class="input-group input-grp-p">
                                            <input class="datepicker-here form-control digits" ngbDatepicker
                                                formControlName="start_date" #f="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn calendar" (click)="f.toggle()" type="button"><i
                                                        class="fa fa-calendar"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-xl-3 col-md-4">
                                        <label>End Date</label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="input-group input-grp-p">
                                            <input class="datepicker-here form-control digits" ngbDatepicker
                                                formControlName="end_date" #c="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn calendar" (click)="c.toggle()" type="button"><i
                                                        class="fa fa-calendar"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-xl-3 col-md-4">
                                        <label>Free Shipping</label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="checkbox checkbox-primary">
                                            <input id="checkbox-primary-1" type="checkbox"
                                                formControlName="free_shipping" data-original-title="" title="">
                                            <label for="checkbox-primary-1">Allow Free Shipping</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-xl-3 col-md-4"><label>Quantity</label></div>
                                    <div class="col-md-7">
                                        <input class="form-control" formControlName="quantity" type="number"
                                        required="">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-xl-3 col-md-4"><label>Discount Type</label></div>
                                    <div class="col-md-7">
                                        <select class="custom-select form-select" required="">
                                            <option value="">--Select--</option>
                                            <option value="1">Percent</option>
                                            <option value="2">Fixed</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-xl-3 col-md-4">
                                        <label>Status</label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="checkbox checkbox-primary">
                                            <input id="checkbox-primary-2" formControlName="status" type="checkbox"
                                                data-original-title="" title="">
                                            <label for="checkbox-primary-2">Enable the Coupon</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                  </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink>Restriction</a>
                  <ng-template ngbNavContent>
                    <form [formGroup]="restrictionForm" class="needs-validation" novalidate="">
                        <h4>Restriction</h4>
                        <div class="form-group row">
                            <div class="col-xl-3 col-md-4"><label for="validationCustom3">Products</label></div>
                            <div class="col-md-7"><input class="form-control" formControlName="products" id="validationCustom3"
                                type="text" required="">
                            </div>
                            <div class="valid-feedback">Please Provide a Product Name.</div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-3 col-md-4"><label>Category</label></div>
                            <div class="col-md-7">
                                <select class="custom-select form-select" formControlName="category" required="">
                                    <option value="">--Select--</option>
                                    <option value="1">Electronics</option>
                                    <option value="2">Clothes</option>
                                    <option value="2">Shoes</option>
                                    <option value="2">Digital</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-3 col-md-4"><label for="validationCustom4">Minimum Spend</label></div>
                            <div class="col-md-7">
                                <input class="form-control" formControlName="min" id="validationCustom4"
                                type="number">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-3 col-md-4">
                                <label for="validationCustom5">Maximum Spend</label>
                            </div>
                            <div class="col-md-7">
                                <input class="form-control" formControlName="max" id="validationCustom5"
                                type="number">
                            </div>
                        </div>
                    </form>
                  </ng-template>
                </li>
                <li [ngbNavItem]="3">
                    <a ngbNavLink>Usage</a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="usageForm" class="needs-validation" novalidate="">
                            <h4>Usage Limits</h4>
                            <div class="form-group row">
                                <div class="col-xl-3 col-md-4"><label for="validationCustom6">Per Limit</label></div>
                                <div class="col-md-7"><input class="form-control" formControlName="limit" id="validationCustom6"
                                    type="number">
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-xl-3 col-md-4"><label for="validationCustom7">Per Customer</label></div>
                                <div class="col-md-7"><input class="form-control" formControlName="customer" id="validationCustom7"
                                    type="number">
                                </div>
                            </div>
                        </form>
                    </ng-template>
                  </li>
              </ul>
              <div [ngbNavOutlet]="nav" class="mt-2"></div>
            <div class="pull-right">
                <button type="button" class="btn btn-primary">Save</button>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

const API_Category = environment.base_url + 'category'
const API_Collection = environment.base_url + 'collection'
const API_Product = environment.base_url + 'products'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};


@Injectable({
  providedIn: 'root'
})
export class ProductService {
  productCreateSubject = new BehaviorSubject<string>('Loading');

  productCreate$ = this.productCreateSubject.asObservable();

  constructor(private http: HttpClient) { }

  categoryGet() {
    return this.http.get<any>(API_Category);
  }

  categoryCreate(data) {
    return this.http.post<any>(API_Category, data);
  }

  categoryDelete(id: number) {
    return this.http.delete<any>(API_Category + '/' + id);
  }

  collectionGet() {
    return this.http.get<any>(API_Collection);
  }

  collectionCreate(data) {
    return this.http.post<any>(API_Collection, data);
  }

  collectionDelete(id: number) {
    return this.http.delete<any>(API_Collection + '/' + id);
  }

  productGet() {
    return this.http.get<any>(API_Product);
  }

  productDelete(id: number) {
    return this.http.delete<any>(API_Product + '/' + id)
  }

  productDetailGet(id: number) {
    return this.http.get<any>(API_Product + '/' + id);
  }

  productCreate(form: any, images: any) {
    this.productCreateSubject.next('Product Sent');
    // console.log("Submitted Product Add form: ", form)
    let product_id = 0;
    const product_create_url = String(API_Product + '/' + 'create')
    const product_create_form = {
      title: form.title,
      description: form.description,
      status: form.status,
      stock: form.stock,
      sku: form.sku,
      weight: form.weight,
      dimension: form.dimension,
      category: form.category,
      new: form.new,
      tags: String(form.tags),
    }
    const product_create_body = JSON.stringify(product_create_form);
    console.log("Sent Product Create: ", product_create_form)
    this.http.post<any>(product_create_url, product_create_body, httpOptions).subscribe((res) => {
      // console.log("Product Create Response:", res)
      this.productCreateSubject.next('Product Created');
      product_id = res.id
      for (let price of form.price) {
        const product_price_url = String(environment.base_url + 'product-price')
        const product_price_form = {
          product: product_id,
          min: price.min,
          max: price.max,
          value: price.value
        }
        const product_price_body = JSON.stringify(product_price_form)
        // console.log("Sent Product Price: ", product_price_form)
        this.http.post<any>(product_price_url, product_price_body, httpOptions).subscribe((res) => {
          // console.log("Product Price Response:", res)
          this.productCreateSubject.next('Product Price Created');
        })
      }
      for (let col of form.collection) {
        const product_collection_url = String(environment.base_url + 'product-collection')
        const product_collection_form = {
          product: product_id,
          collection: col,
        }
        const product_collection_body = JSON.stringify(product_collection_form)
        // console.log("Sent Product Collection: ", product_collection_form)
        this.http.post<any>(product_collection_url, product_collection_body, httpOptions).subscribe((res) => {
          // console.log("Product Collection Response:", res)
          this.productCreateSubject.next('Product Collection Created');
        })
      }
      for (let opt of form.option) {
        const product_option_url = String(environment.base_url + 'product-options')
        const product_option_form = {
          product: product_id,
          name: opt.name,
          sub_options: opt.sub_options
        }
        const product_option_body = JSON.stringify(product_option_form)
        // console.log("Sent Product Option: ", product_option_form)
        this.http.post<any>(product_option_url, product_option_body, httpOptions).subscribe((res) => {
          // console.log("Product Collection Response:", res)
          this.productCreateSubject.next('Product Options Created');
        })
        const product_images_url = String(environment.base_url + 'product-images')
        // console.log(images)
        for (let url of images) {
          const formData = new FormData();
          const file = ImageURLtoFile(url.img)
          // console.log(file)
          formData.append('product', String(17));
          formData.append('image', file);
          this.http.post<any>(product_images_url, formData).subscribe((res) => {
            // console.log("Product Image Response:", res)
            this.productCreateSubject.next('Product Images Created');
          });
        }
      }
    })
    this.productCreateSubject.next('Product Create Completed');
  }
  
}

function ImageURLtoFile(data: string) {
  const splitDataURI = data.split(',')
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)
  const blob = new Blob([ia], { type: mimeString })
  const file = new File([blob], "image.jpg", { type: mimeString });
  return file
}

<div class="container-fluid">
    <div class="row products-admin ratio_asos">
        <div class="col-xl-3 col-sm-6" *ngFor="let product of product_list">
            <div class="card">
                <div class="card-body product-box">
                    <div class="img-wrapper">
                        <div class="lable-block"><span class="lable3" *ngIf="product.tag == 'new'">new</span> <span
                                class="lable4" *ngIf="product.sale == 'on sale'">on sale</span></div>
                        <div class="front">
                            <a href="javascript:void(0)"><img [src]="[product.images[0].image_url]"
                                    class="img-fluid blur-up lazyload bg-img" alt=""></a>
                            <div class="product-hover">
                                <ul>
                                    <li>
                                        <button class="btn" type="button" data-original-title="" title="" (click)="openDetail(product.id)"><i
                                                class="icon-pencil-alt"></i></button>
                                    </li>
                                    <li>
                                        <button class="btn" type="button" data-toggle="modal"
                                            data-target="#exampleModalCenter" data-original-title="" title=""  (click)="openDelete(content, product.id)"><i
                                                class="icon-trash"></i></button>
                                    </li>
                                    <ng-template #content let-modal>
                                        <div class="modal-header">
                                            <h5 class="modal-title f-w-600" id="exampleModalLabel">Delete product ID {{product.id}} ?</h5>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn" style="background-color: red; color: white;" (click)="onDelete(product.id); modal.dismiss('Cross click')">Delete</button>
                                            <button class="btn btn-secondary" type="button" data-dismiss="modal"(click)="modal.dismiss('Cross click')">Close</button>
                                        </div>
                                    </ng-template>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="product-detail">
                        <a href="javascript:void(0)">
                            <h4>{{product.title | uppercase}}</h4>
                        </a>
                        <h6>Rp.{{product.price[0].value}}</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public registerForm: FormGroup;
  public active = 1;
  submitted = false;

  constructor(private formBuilder: UntypedFormBuilder, private authService: AuthService, private router: Router) { }

  owlcarousel = [
    {
      title: "Welcome to Printwave",
      desc: "Please login using your admin credentials",
    },
  ]
  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true
  };

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', (Validators.required, Validators.email)],
      password: ['', Validators.required],
    })
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      console.log(this.loginForm)
      alert (
        'The login credentials did not match requirements'
      )
      return;
    }
    if (this.loginForm.valid) {
      // console.log(this.loginForm.value);
      this.authService.login(this.loginForm.value).subscribe({
        next: (data) => {
          this.authService.isAuthenticated = true;
          localStorage.setItem(this.authService.authSecretKey, data.accesstoken);
          this.authService.getCurrentUser().subscribe((user) => {
            // console.log(user)
            if (user.role == 1) {
              console.log("Login successful, Welcome ", user.first_name)
              this.router.navigateByUrl('/products/product-list');
              return
            } else {
              alert('ERROR! Please use an admin user')
              this.loginForm.reset()
              this.authService.logout()
            }
          })
          // console.log("Access Token: " + data.accesstoken)
        },
        error: (error) => {
          alert ('ERROR! ' + error.error.detail + ' Please try again')
          this.loginForm.reset()
        },
      });
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ProductService } from 'src/app/shared/service/product.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {
  public Editor = ClassicEditor;
  public counter: number = 1;
  public images = [
    {img: null,},
    {img: null,},
    {img: null,},
    {img: null,},
    {img: null,}
  ]
  thumbnail = null;

  categories = []
  collections = []

  constructor(
    private fb: UntypedFormBuilder,
    private productService: ProductService,
    private router: Router
  ) {}
  
  productForm: FormGroup
  submitted = false
  collectionsHolder: string[] = [];
  tagsHolder: string[] = [];
  
  ngOnInit() {
    this.productForm = this.fb.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      status: [true, [Validators.required]],
      price: this.fb.array([
        this.fb.group({
          min: ['', [Validators.required, Validators.min(1)]],
          max: ['', [Validators.required, Validators.min(2), Validators.max(9999)]],
          value: ['', [Validators.required, Validators.min(1)]],
        })
      ]),
      stock: [1, [Validators.required, Validators.min(1)]],
      sku: ['', [Validators.required]],
      weight: [1, [Validators.required]],
      dimensions: ['', [Validators.required]],
      category: ['', [Validators.required]],
      collection: [[], [Validators.required]],
      new: [true, [Validators.required]],
      tags: [[], [Validators.required]],
      images: [null],
      option: this.fb.array([
        this.fb.group({
          name: ['', Validators.required],
          sub_options: this.fb.array([
            this.fb.group({
              name: ['', Validators.required],
              prices: this.fb.array([
                this.fb.group({
                  min: ['', [Validators.required, Validators.min(1)]],
                  max: ['', [Validators.required, Validators.min(2), Validators.max(9999)]],
                  value: ['', [Validators.required, Validators.min(1)]],
                })
              ])
            })
          ]),
        })
      ]),
    })
    this.productService.categoryGet().subscribe((res) => {
      // console.log("Category List: ", res)
      this.categories = res
    })
    this.productService.collectionGet().subscribe((res) => {
      // console.log("Collection List: ", res)
      this.collections = res
    })
  }

  get options(): FormArray {
    return (this.productForm.get('option') as FormArray);
  }

  get prices(): FormArray {
    return (this.productForm.get('price') as FormArray);
  }

  getSuboptions(suboptionIndex: number) {
    return (this.options.at(suboptionIndex) as FormGroup).get('sub_options') as FormArray;
  }

  getPrice(suboptionIndex: number, priceIndex: number) {
    return (this.getSuboptions(suboptionIndex).at(priceIndex) as FormGroup).get('prices') as FormArray;
  }
  
  addOption() {
    let option = this.fb.group({
      name: ['', Validators.required],
      sub_options: this.fb.array([
        this.fb.group({
          name: ['', Validators.required],
          prices: this.fb.array([
            this.fb.group({
              min: ['', [Validators.required, Validators.min(1)]],
              max: ['', [Validators.required, Validators.min(9999)]],
              value: ['', [Validators.required, Validators.min(1)]],
            })
          ])
        })
      ]),
    });
    this.options.push(option);
  }

  addBasePrice() {
    let price = this.fb.group({
      min: ['', [Validators.required, Validators.min(1)]],
      max: ['', [Validators.required, Validators.min(9999)]],
      value: ['', [Validators.required, Validators.min(1)]],
    })
    this.prices.push(price);
  }
  
  deleteOption(index: number) {
    this.options.removeAt(index);
  }

  deleteBasePrice(index: number) {
    this.prices.removeAt(index);
  }
  
  addSuboption(suboptionIndex: number) {
    const suboption = this.fb.group({
      name: ['', Validators.required],
      prices: this.fb.array([
        this.fb.group({
          min: ['', [Validators.required, Validators.min(1)]],
          max: ['', [Validators.required, Validators.min(9999)]],
          value: ['', [Validators.required, Validators.min(1)]],
        })
      ])
    });
    this.getSuboptions(suboptionIndex).push(suboption);
  }

  deleteSuboption(suboptionIndex: number, index) {
    this.getSuboptions(suboptionIndex).removeAt(index);
  }

  addPrice(suboptionIndex: number, priceIndex: number) {
    const prices = this.fb.group({
      min: ['', [Validators.required, Validators.min(1)]],
      max: ['', [Validators.required, Validators.min(9999)]],
      value: ['', [Validators.required, Validators.min(1)]]
    });
    this.getPrice(suboptionIndex, priceIndex).push(prices);
  }

  deletePrice(suboptionIndex: number, priceIndex: number, prc: number) {
    this.getPrice(suboptionIndex, priceIndex).removeAt(prc)
  }

  dimensionL = 0
  dimensionW = 0
  dimensionT = 0

  onCollectionsSetKeydown() {
    if (this.productForm.value.collection == "" || this.productForm.value.collection == null) return;  
    this.collectionsHolder.push(this.productForm.value.collection);
    this.productForm.controls['collection'].setValue("");
  }
  
  dropCollection(index: any) {
    this.collectionsHolder.splice(index, 1);  
  }

  onTagsSetKeydown() {  
    if (this.productForm.value.tags == "" || this.productForm.value.tags == null) return;  
    this.tagsHolder.push(this.productForm.value.tags);
    this.productForm.controls['tags'].setValue("");
  }
  
  dropTag(index: any) {
    this.tagsHolder.splice(index, 1);  
  }

  //FileUpload
  readImages(event: any, i) {
    if (event.target.files.length === 0)
      return;
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.images[i].img = reader.result.toString();
      if (this.images[0].img != null) {
        this.thumbnail = ImageURLtoFile(this.images[0].img);
        console.log(this.thumbnail)
      }
    }
    // console.log(this.url)
    // console.log(this.images)
  }

  deleteImage(i) {
    this.images[i].img = null
    if (i == 0) {
      this.thumbnail = null
    }
    // console.log(this.images)
  }

  get f(): { [key: string]: AbstractControl } {
    return this.productForm.controls;
  }

  public onEditorChange( { editor }: ChangeEvent ) {
    const data = editor.getData();
    this.productForm.controls['description'].setValue(data);
  }
  
  onSubmit() {
    this.submitted = true;
    this.productForm.controls['dimensions'].setValue(String(this.dimensionL + "x" + this.dimensionW + "x" + this.dimensionT));
    this.productForm.controls['collection'].setValue(this.collectionsHolder);
    this.productForm.controls['tags'].setValue(this.tagsHolder);
    console.log(this.productForm)
    console.log(this.productForm.value)
    // if (this.productForm.invalid) {
    //   return;
    // }
    this.productService.productCreate(this.productForm.value, this.images)
    this.productService.productCreateSubject.subscribe((res) => {
      console.log("Product Create Progres: ", res)
      if (res == 'Product Create Completed') {
        this.router.navigateByUrl('/products', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/products/add-product']);
        });
      }
    })
  }

  onReset() {
    this.submitted = false;
    this.productForm.reset();
  }
}

function ImageURLtoFile(data: string) {
  const splitDataURI = data.split(',')
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)
  const blob = new Blob([ia], { type: mimeString })
  const file = new File([blob], "image.jpg", { type: mimeString });
  return file
}
<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Menu Lists</h5>
                </div>
                <div class="card-body">
                    <div class="custom-datatable">
                        <button class="btn btn-primary me-1 mb-3" style="float: right;" >Delete</button>
                        <div class="clearfix"></div>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th scope="col" sortable="name" (sort)="onSort($event)">Name</th>
                                    <th scope="col" sortable="status" (sort)="onSort($event)">Status</th>
                                    <th scope="col" sortable="created_on" (sort)="onSort($event)">Created On</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let item of tableItem$ | async ">
                                    <th> <input type="checkbox" name="title_select" onclick="onSelect(item.id)"></th>
                                <td scope="row">
                                    {{item.name}}
                                </td>
                                <td>
                                    <i class="fa fa-circle {{item.status}} f-12"></i>
                                </td>
                                <td>{{item.created_on}}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="d-flex justify-content-center p-2">
                            <ngb-pagination
                              [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                            </ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>